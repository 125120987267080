import { createTheme } from '@mui/material/styles';

//export const IS_MOBILE_APP = false; //process.env.IS_MOBILE || window.cordova;

export const APP_TITLE = process.env.REACT_APP_TITLE;

export const theme = createTheme({
    direction: 'ltr',
    palette: {
        primary: {
            main: process.env.REACT_APP_PRIMARY_MAIN_COLOR,
            light: process.env.REACT_APP_PRIMARY_LIGHT_COLOR
        },
        secondary: {
            main: process.env.REACT_APP_SECONDARY_MAIN_COLOR
        },
        warning: {
            main: process.env.REACT_APP_PRIMARY_LIGHT_COLOR
        }
    },
});