import React, {useEffect, useState} from 'react';
import styles from "./SimpleToast.module.scss";
import {IconButton, Slide} from "@mui/material";
import {IoMdClose} from 'react-icons/io';

const SimpleToast = props => {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return open ?
        (<div className={styles.ToastContainer}>
            <Slide in direction={"up"} mountOnEnter unmountOnExit>
                <section className={styles.Toast} onClick={props.onClose}>
                    <h3>{props.children}</h3>
                    <IconButton size={"small"}>
                        <IoMdClose/>
                    </IconButton>
                </section>
            </Slide>
        </div>) : null;

};

export default SimpleToast;

SimpleToast.defaultProps = {
    open: false,
    onClose: () => {
    }
}