import CryptoJS from "crypto-js";
import md5 from "md5";

export const decryptAES_ECB = (encrypted_message, key) => {

    const KeyObj = CryptoJS.enc.Utf8.parse(md5(key));

    const bytes  = CryptoJS.AES.decrypt(encrypted_message, KeyObj, {
        mode: CryptoJS.mode.ECB,
    });

    // console.log('______________');
    // console.log(md5(key));
    // console.log(encrypted_message);
    // console.log(decryptedData);

    return bytes.toString(CryptoJS.enc.Utf8);

};