import React, {Suspense} from 'react';
import { createRoot } from 'react-dom/client';
import 'assets/styles/index.scss';
import App from './App';
import {BrowserRouter} from "react-router-dom";


import './axios';

//import './i18n';
//import {RTL} from "./RTL";

import {theme} from './theme';
import {ThemeProvider} from "@mui/material/styles";
//import SplashScreen from "./components/Layout/Loader/SplashScreen";



//const ActiveRouter = IS_MOBILE_APP ? HashRouter : BrowserRouter;

const startApp = () => {

    const appContent = (
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                        <Suspense fallback={<></>}>
                            <BrowserRouter>
                                <App/>
                            </BrowserRouter>
                        </Suspense>
            </ThemeProvider>
        </React.StrictMode>
    );

    createRoot(document.getElementById('root')).render(
        appContent
    );
}

if (window.cordova) {
    document.addEventListener('deviceready', startApp, false);
} else {
    startApp();
}





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
