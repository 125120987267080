import React, {useCallback, useEffect, useState} from 'react';
import Layout from "../Layout/Layout";
import PinCode from "../Verify/PinCode";
import {useParams} from "react-router";
import CircularProgress from '@mui/material/CircularProgress';
import {Link} from "react-router-dom";
import {MdOutlineReportGmailerrorred} from "react-icons/md";
import Result from "../Verify/Result";
import {sha256} from "hash.js";
//import alert from "../../lib/Alert/Alert";
import {apiCallPost} from "@semiorbit/api-central";
import {decryptAES_ECB} from "../../lib/aes";


function Home() {

    let output;

    const [state, setState] = useState(0);

    // eslint-disable-next-line no-unused-vars
    const [pinCode, setPinCode] = useState(null);
    
    const [password, setPassword] = useState('');
    
    const [tryCount, setTryCount] = useState(0);

    const {token} = useParams();

    


    useEffect(() => {
        if (token.toString().trim() === '') setState(-3);
    }, [token]);

    const pinEntered = useCallback((result) => {


        if (result.length === 6) {

            if (tryCount >= 6) {
                setState(-3);
                return;
            }
            setTryCount(tryCount + 1);

            setState(-2);
            setPinCode(result);

            const enc_pin = sha256().update(result).digest('hex');

            apiCallPost('pk/validate', {
                hpin : enc_pin,
                guid : token
            }).then(res => {
                //console.log(res.data);
                if (res.data.cod && res.data.state === 1)
                    setPassword(decryptAES_ECB(res.data.cod, result));
                setState(parseInt(res.data.state));
            });

        } else {
            setState(0);
        }
    }, [token, tryCount]);


    switch (state) {

        case -3:
            output = (
                <div>
                    <span><MdOutlineReportGmailerrorred style={{fontSize: "4em", color: "#CE9A2B"}}/></span>
                    <h3>Invalid Token!</h3>
                </div>
            );
            break;
        case -2:
            output = (
                <div>
                    <CircularProgress color={"warning"}/>
                    <h3>Validating in Progress ... </h3>
                </div>);
            break;
        case -1:
            output = (
                <div>
                    <span><MdOutlineReportGmailerrorred style={{fontSize: "4em", color: "#CE9A2B"}}/></span>
                    <h3>Invalid Pin Code, Please <Link onClick={() => setState(0)} to={""}>Try Again</Link></h3>
                </div>
            );
            break;
        case 0:
            output = (<PinCode onChange={pinEntered}/>);
            break;
        case 1:
            output = (
              <Result password={password} />
            );
            break;
        default:
            break;


    }

    return (
        <Layout>
            {output}
        </Layout>
    );

}

export default Home;
