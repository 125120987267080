import React from 'react';
import styles from "./InfoItem.module.scss";
import {Grid} from "@mui/material";

const InfoItem = props => {

    return (
        <Grid item lg={3} md={4} sm={6} xs={12}>
            <div className={styles.InfoItem}>
                <picture>
                    {props.icon}
                </picture>
                <h2>{props.title}</h2>
                <span>
                {props.children}
            </span>
            </div>
        </Grid>
    );

};

export default InfoItem;
