import React, {useCallback, useState} from 'react';
import styles from "./Result.module.scss";
import {IconButton} from "@mui/material";
import {AiOutlineCopy} from "react-icons/ai";
import {IoShieldCheckmarkSharp} from "react-icons/io5";
import QRCode from "react-qr-code";
import SimpleToast from "../../lib/SimpleToast/SimpleToast";


const Result = props => {

    const [open, setOpen] = useState(false);


    const copyHandler = useCallback(() => {
        navigator.clipboard.writeText(props.password).then(() => {
            setOpen(true);
        });
    }, [props.password]);

    return (
        <>
            <section className={styles.Result}>
                <IoShieldCheckmarkSharp style={{fontSize: "4em", color: "lightgreen"}}/>
                <div className={styles.Password}>
                    <code>{props.password}</code>
                    <IconButton color="secondary" aria-label="Copy" onClick={copyHandler}>
                        <AiOutlineCopy/>
                    </IconButton>
                </div>
                <h4>Please click copy button and save password securely in your browser!</h4>
                <h4>Don't share it in a chat or email.</h4>

                <h1>To use password on your mobile please scan:</h1>
                <br/>

                <div className={styles.QRCode}>
                    <QRCode value={props.password}/>
                </div>

            </section>
            <SimpleToast open={open} onClose={() => {
                setOpen(false);
            }}>Copied!</SimpleToast>
        </>
    );

};

export default Result;
