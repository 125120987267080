import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from "./PinCode.module.scss";

const PinCode = props => {

    const itemsRef = useRef([]);

    const [pin, setPin] = useState(['','','','','','']);

    useEffect(() => {
        itemsRef.current[0].focus();
    }, [itemsRef])

    const changeHandler = useCallback((e, idx) => {
        const pinList = [...pin];
        pinList[idx] = e.target.value;
        setPin(pinList);
        const result = pinList.join('').toString();
        props.onChange(result);
        if (idx < 5) itemsRef.current[idx + 1].focus();
    }, [pin, props]);
    
    return (
        <section className={styles.PinCode}>
            <h3>
                Enter your PIN code:
            </h3>
            <div className={styles.PinCodeInput}>
                {pin.map((v,i) =>
                    <input type={"text"}
                           name={"pin-code[]"}
                           key={i}
                           id={"pin"+i}
                           value={v}
                           min={0}
                           max={9}
                           autoComplete={"new-password"}
                           maxLength={1}
                           tabIndex={i+1}
                           onInput={(e) => {
                               e.target.value=e.target.value.replace(/[^0-9]{1}/g,'')
                           }}
                           ref={(ref) => itemsRef.current.push(ref)}
                           onChange={(e) => changeHandler(e, i)} />)}
            </div>
        </section>
    );

};

export default PinCode;
