import React from 'react';
import styles from "./Info.module.scss";
import InfoItem from "./InfoItem";

import {GrSecure} from "react-icons/gr";
import {GrDocumentExcel} from "react-icons/gr";
import {CgPassword} from "react-icons/cg";
import {FaRobot} from "react-icons/fa";
import {TbVirusSearch} from "react-icons/tb";
import {RiSpyLine} from "react-icons/ri";
import {MdPublishedWithChanges} from "react-icons/md";
import {BsChatText} from "react-icons/bs";
import {TbMailOff} from "react-icons/tb";
import {TfiPrinter} from "react-icons/tfi";
import {MdOutlinePassword} from "react-icons/md";
import {AiOutlineChrome} from "react-icons/ai";
import {Grid} from "@mui/material";


const Info = props => {

    return (
        <div className={styles.InfoList}>

            <Grid container>
                <InfoItem icon={<GrSecure className={styles.WhiteStroke}/>}
                          title={"Keep Passwords Safe & Encrypted in Browser"}>
                    Please keep your password in safe containers only: like your browser password manager.
                </InfoItem>

                <InfoItem icon={<GrDocumentExcel className={styles.WhiteStroke}/>}
                          title={"Avoid Saving Credentials in Files"}>
                    Do not save passwords in text files or excel sheets.</InfoItem>

                <InfoItem icon={<MdPublishedWithChanges/>} title={"Change Passwords Periodically"}>Password should be
                    changed periodically.</InfoItem>

                <InfoItem icon={<FaRobot/>} title={"Use Hard Passwords"}>Password should be hard enough to be guessed by
                    robots.</InfoItem>

                <InfoItem icon={<TbVirusSearch/>} title={"Scan Your Devices for Malware"}>Always scan your devices for
                    malware.</InfoItem>

                <InfoItem icon={<RiSpyLine/>} title={"Avoid Anonymous or Untrusted Links in Emails"}>Carefully read
                    domain name in urls that you click from emails or messages to avoid fraud.</InfoItem>

                <InfoItem icon={<CgPassword/>} title={"Pass Passwords in Encrypted Message or Voice Call"}>Pass
                    passwords to your team verbally or by using safe tools.</InfoItem>

                <InfoItem icon={<BsChatText/>} title={"Never Share Passwords on WhatsApp or Other Chat Apps"}>Never pass
                    passwords on WhatsApp or chat apps.</InfoItem>

                <InfoItem icon={<TbMailOff/>} title={"Never Send Password in Email!"}>Never send password in
                    email!</InfoItem>

                <InfoItem icon={<TfiPrinter/>} title={"Printing Out Passwords on Paper is Alright"}>To keep passwords
                    safe either use encrypted databases or print out on paper and keep safely.</InfoItem>

                <InfoItem icon={<MdOutlinePassword/>} title={"Never Use Same Password on Multiple Websites"}>Never ever
                    use same password on multiple random websites.</InfoItem>

                <InfoItem icon={<AiOutlineChrome/>} title={"Check Compromised Passwords List in Chrome"}>Check online
                    for compromised passwords lists (Google Chrome helps in this).</InfoItem>

            </Grid>
        </div>
    );

};

export default Info;
