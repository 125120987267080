import React from 'react';
import styles from "./Block.module.scss";
import LogoShield from "../../assets/theme/logo/semiorbit-sheild-light.svg";

const Block = () => {

    return (
        <div className={`context-dark ${styles.BlankPage}`}>
            <h1>
                <img src={LogoShield} className={styles.LogoShield} alt="SEMIORBIT PASSKEYS"/>
                <div>ERROR 404</div>
            </h1>
        </div>
    );

};

export default Block;
