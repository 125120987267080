import {Route, Routes} from "react-router";
import Home from "./components/Home/Home";
import Block from "./components/Verify/Block";



function App() {

  const routes = (

      <Routes>


        <Route path="/:token" element={<Home/>}/>

        <Route path="/*" element={<Block/>}/>

      </Routes>

  );


  return (<>{routes}</>);


}

export default App;
