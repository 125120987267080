import React from 'react';
import Logo from 'assets/theme/logo/logo-light.svg';
import LogoShield from 'assets/theme/logo/semiorbit-sheild-light.svg';
import styles from "./Layout.module.scss";
import Info from "../Info/Info";

const Layout = props => {

    return (
        <main className={`context-dark`}>
            <div className={styles.PgContainer}>
                <section className={`context-dark ${styles.BrandPanel}`}>

                            <img src={LogoShield} className={styles.LogoShield} alt="SEMIORBIT PASSKEYS"/>

                            <img src={Logo} alt="SEMIORBIT SOLUTIONS" className={styles.Logo} />
                            <h1>SECURITY CONSOLE</h1>

                            <div className={`context-dark ${styles.VerifyPanel}`}>
                                {props.children}
                            </div>

                </section>

                <section className={styles.InfoPanel}>
                    <Info />
                </section>
            </div>

            <footer>
                All rights reserved for <a href="https://semiorbit.com" target={'_blank'} rel="noreferrer">Semiorbit Solutions</a> &copy;
                {new Date().getFullYear()}
            </footer>
        </main>
    );

};

export default Layout;
